<template>
  <div>
    <h3>返利基准数据</h3>
    <el-divider></el-divider>
    <div class="variable_box">
      <el-row :gutter="20">
        <el-col :span="4"
                v-for="(item,i) in rebateVariable"
                :key="i">
          <div :class="{'variable_col' : true, 'is-var' : item.banClick}"
               @click="addVariable(item)">{{item.name || ''}} ({{item.code}})</div>
        </el-col>
      </el-row>
    </div>
    <h3>公式符号</h3>
    <el-divider></el-divider>
    <div class="variable_box">
      <el-row :gutter="20">
        <el-col :span="2"
                v-for="(item,i) in rebateSymbol"
                :key="i">
          <div :class="{'variable_col' : true,'is-brackets' : item.banClick}"
               @click="addVariable(item)">{{item.name || ''}} ({{item.code}})</div>
        </el-col>
      </el-row>
    </div>
    <h3>数字</h3>
    <el-divider></el-divider>
    <div class="variable_box">
      <el-row :gutter="20">
        <el-col :span="2"
                v-for="(item,i) in rebateNumber"
                :key="i">
          <div :class="{'variable_col' : true,'is-var' : item.banClick}"
               @click="addVariable(item)">{{item.name || ''}} ({{item.code}})</div>
        </el-col>
      </el-row>
    </div>
    <h3>返利公式</h3>
    <el-divider></el-divider>
    <div class="variable_box">
      <el-button @click="addExpression"
                 type="text"
                 icon="el-icon-circle-plus-outline">添加公式</el-button>
      <el-row :gutter="60"
              v-for="(item,i) in expressionName"
              :key="i">
        <el-col :span="12">
          <div class="top">
            <span>返利条件</span>
            <span>
              <el-button type="text"
                          @click="backVariable(i,'condition')"
                         icon="el-icon-back">后退</el-button>
              <el-button type="text"
                          @click="clearVariable(i,'condition')"
                         icon="el-icon-delete">清空</el-button>
            </span>
          </div>
          <el-input type="textarea"
                    readonly
                    @focus="setVariable(i,'condition')"
                    @blur="blurVariable"
                    :class="{'focus-text' : focusDemo && focusDemo.index === i && focusDemo.key === 'condition' ,text : true}"
                    placeholder="请输入内容"
                    v-model="item.conditionExpress">
          </el-input>
        </el-col>
        <el-col :span="12">
          <div class="top">
            <span>返利公式</span>
            <span>
              <el-button type="text"
                          @click="backVariable(i,'compute')"
                         icon="el-icon-back">后退</el-button>
              <el-button type="text"
                          @click="clearVariable(i,'compute')"
                         icon="el-icon-delete">清空</el-button>
            </span>
          </div>
          <el-input type="textarea"
                    readonly
                    @focus="setVariable(i,'compute')"
                    @blur="blurVariable"
                    :class="{'focus-text' : focusDemo && focusDemo.index === i && focusDemo.key === 'compute' ,text : true}"
                    placeholder="请输入内容"
                    v-model="item.computeExpress">
          </el-input>
        </el-col>
      </el-row>
    </div>

  </div>

</template>

<script>
import request from '../../../utils/request';

const rebateSymbol = [
  {
    name: '加',
    code: '+',
    key: 'symbol',
  },
  {
    name: '减',
    code: '-',
    key: 'symbol',
  },
  {
    name: '乘',
    code: '*',
    key: 'symbol',
  },
  {
    name: '除',
    code: '/',
    key: 'symbol',
  },
  {
    name: '大于',
    code: '>',
    key: 'symbol',
  },
  {
    name: '小于',
    code: '<',
    key: 'symbol',
  },
  {
    name: '小于等于',
    code: '<=',
    key: 'symbol',
  },
  {
    name: '大于等于',
    code: '>=',
    key: 'symbol',
  },
  {
    name: '等于',
    code: '==',
    key: 'symbol',
  },
  {
    name: '并且',
    code: '&&',
    key: 'symbol',
  },
  {
    name: '或',
    code: '||',
    key: 'symbol',
  },
  {
    name: '左括号',
    code: '(',
    key: 'symbol',
  },
  {
    name: '右括号',
    code: ')',
    key: 'symbol',
  },
  {
    name: '取余',
    code: '%',
    key: 'symbol',
  },
  {
    name: '取整',
    code: 'INT',
    key: 'symbol',
  },
];

const rebateNumber = [
  {
    code: '0',
    key: 'number',
  },
  {
    code: '1',
    key: 'number',
  },
  {
    code: '2',
    key: 'number',
  },
  {
    code: '3',
    key: 'number',
  },
  {
    code: '4',
    key: 'number',
  },
  {
    code: '5',
    key: 'number',
  },
  {
    code: '6',
    key: 'number',
  },
  {
    code: '7',
    key: 'number',
  },
  {
    code: '8',
    key: 'number',
  },
  {
    code: '9',
    key: 'number',
  },
  {
    code: '.',
    key: 'number',
  },
];

const expressionModule = {
  conditionExpress: '',
  computeExpress: '',
  conditionJson: [],
  computeJson: [],
};

export default {
  name: 'ExpressionForm',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    name: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rebateVariable: [],
      rebateSymbol,
      rebateNumber,
      expressionList: [],
      expressionName: [],
      focusDemo: null,
    };
  },
  created() {
    this.searchVariable();
    if (this.list.length > 0) {
      this.expressionList = JSON.parse(JSON.stringify(this.list || []));
      this.expressionName = JSON.parse(JSON.stringify(this.name || []));
    }
  },
  methods: {
    // 查询变量接口
    searchVariable() {
      request
        .post('/rebate/m/rebateVariable/show')
        .then((res) => {
          if (res.success) {
            this.rebateVariable = res.result;
          }
        });
    },
    // 添加公式
    addExpression() {
      this.expressionList.push(JSON.parse(JSON.stringify(expressionModule || '[]')));
      this.expressionName.push(JSON.parse(JSON.stringify(expressionModule || '[]')));
      this.focusDemo = null;
      this.mapVariable();
      this.mapSymbol();
      this.mapNumber();
    },
    // 添加变量符号数字
    addVariable(item) {
      if (this.focusDemo) {
        if (item.banClick) return false;
        const { index, key } = this.focusDemo;
        this.expressionList[index][`${key}Json`].push({
          name: item.name,
          code: item.code,
          key: item.key,
        });
        const list = JSON.parse(JSON.stringify(this.expressionList[index][`${key}Json`]));
        this.expressionList[index][`${key}Express`] = this.handleVariable(list);
        this.expressionName[index][`${key}Express`] = this.handleVariableName(list);
      }
    },
    // 公式获取焦点
    setVariable(index, key) {
      console.log(this.expressionList[index]);
      this.focusDemo = { index, key };
      const { length } = this.expressionList[index][`${key}Json`];
      if (length > 0) {
        // 根据最后一个数据判断表单变量状态
        const data = this.expressionList[index][`${key}Json`];
        const lastVar = data[data.length - 1];
        this.mapVariable(lastVar);
        this.mapSymbol(lastVar);
        this.mapNumber(lastVar);
      } else {
        this.mapVariable();
        this.mapSymbol();
        this.mapNumber();
      }
    },
    // 公式失去焦点
    blurVariable() {
      // this.focusDemo = null;
    },
    // 循环变量
    mapVariable(lastVar) {
      this.rebateVariable.map((v) => {
        const rowData = v;
        rowData.banClick = false;
        if (lastVar) {
          if (!lastVar.key) {
            rowData.banClick = true;
          }
          if (lastVar.code === 'INT' && lastVar.key === 'symbol') {
            rowData.banClick = true;
          }
          if (lastVar.key === 'number') {
            rowData.banClick = true;
          }
        }

        return rowData;
      });
    },
    // 循环符号
    mapSymbol(lastVar) {
      this.rebateSymbol.map((v) => {
        const rowData = v;
        rowData.banClick = false;
        if (lastVar) {
          if (lastVar.code === 'INT') {
            rowData.banClick = true;
          }
          if (rowData.code === '(' || rowData.code === ')') {
            rowData.banClick = false;
          }
        }
        return rowData;
      });
    },
    // 循环数字
    mapNumber(lastVar) {
      this.rebateNumber.map((v) => {
        const rowData = v;
        rowData.banClick = false;
        if (lastVar) {
          if (!lastVar.key) {
            rowData.banClick = true;
          }
          if (lastVar.code === 'INT' && lastVar.key === 'symbol') {
            rowData.banClick = true;
          }
        }
        return rowData;
      });
    },
    // 监听变量
    handleVariable(data) {
      let name = '';
      data.forEach((v) => {
        // const rowData = v;
        // 编译显示的字符串
        if (v.key === 'number') {
          name += `${v.code}`;
        } else {
          name += ` ${v.code} `;
        }
        // return rowData;
      });
      // 根据最后一个数据判断表单变量状态
      const lastVar = data[data.length - 1];
      this.mapVariable(lastVar);
      this.mapSymbol(lastVar);
      this.mapNumber(lastVar);
      return name;
    },
    // 监听名称变量
    handleVariableName(data) {
      let name = '';
      data.forEach((v) => {
        // const rowData = v;
        // 编译显示的字符串
        if (!v.key) {
          name += ` ${v.name} `;
        } else if (v.key === 'symbol') {
          name += ` ${v.code} `;
        } else if (v.key === 'number') {
          name += `${v.code}`;
        }
        // return rowData;
      });
      return name;
    },
    // 回退
    backVariable(index, key) {
      if (this.focusDemo) {
        const { length } = this.expressionList[index][`${key}Json`];
        this.expressionList[index][`${key}Json`].splice(length - 1, 1);
        this.expressionList[index][`${key}Express`] = this.handleVariable(
          this.expressionList[index][`${key}Json`],
        );
        this.expressionName[index][`${key}Express`] = this.handleVariableName(
          this.expressionList[index][`${key}Json`],
        );
        if (length - 1 > 0) {
          // 根据最后一个数据判断表单变量状态
          const data = this.expressionList[index][`${key}Json`];
          const lastVar = data[data.length - 1];
          this.mapVariable(lastVar);
          this.mapSymbol(lastVar);
          this.mapNumber(lastVar);
        } else {
          this.mapVariable();
          this.mapSymbol();
          this.mapNumber();
        }
      }
    },
    // 清空
    clearVariable(index, key) {
      this.expressionList[index][`${key}Json`] = [];
      this.expressionList[index][`${key}Express`] = this.handleVariable(
        this.expressionList[index][`${key}Json`],
      );
      this.expressionName[index][`${key}Express`] = this.handleVariableName(
        this.expressionList[index][`${key}Json`],
      );
      this.mapVariable();
      this.mapSymbol();
      this.mapNumber();
    },
  },
};
</script>

<style lang="less" scoped>
.variable_box {
  padding: 0 20px;
  .variable_col {
    margin-bottom: 20px;
    cursor: pointer;
  }
  .is-var ,.is-brackets{
    color: #DCDFE6;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text {
    margin-bottom: 20px;
  }
  .focus-text {
    /deep/.el-textarea__inner {
      border: 1px solid #409eff;
    }
  }
}
</style>
