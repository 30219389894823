<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import PolicyArea from './components/policy_area.vue';
import ProductArea from './components/product_area.vue';
import RebateExpression from '../../../../../../components/rebate_expression/rebate_expression.vue';

formCreate.component('PolicyArea', PolicyArea);
formCreate.component('ProductArea', ProductArea);
formCreate.component('RebateExpression', RebateExpression);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      expressionArea: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getFormRule('dms_rebate_policy_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'rebateProductList') {
        rowData.value = {
          product: [],
          productLevel: [],
        };
        rowData.props = {
          ...rowData.props,
          title: '返利',
          isView: this.formConfig.isView || false,
        };
      }
      if (rowData.field === 'assessProductList') {
        rowData.value = {
          product: [],
          productLevel: [],
        };
        rowData.props = {
          ...rowData.props,
          title: '考核',
          isView: this.formConfig.isView || false,
        };
      }
      if (rowData.field === 'onAccountType') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            if (val === '1') {
              this.setValue({
                rebateProductList: {
                  product: [],
                  productLevel: [],
                },
              });
              this.hiddenFields(true, ['rebateProductList', 'rebateProductListTitle']);
            } else {
              this.hiddenFields(false, ['rebateProductList', 'rebateProductListTitle']);
            }
          },
        };
      }
      if (rowData.field === 'policyArea') {
        rowData.value = {
          orgs: [],
          nonOrgs: [],
          customers: [],
          nonCustomers: [],
        };
        rowData.props = {
          ...rowData.props,
          isView: this.formConfig.isView || false,
        };
      }
      if (rowData.field === 'rebateExpressionVos') {
        rowData.on = {
          onExpression: this.onExpression,
        };
        rowData.props = {
          ...rowData.props,
          isView: this.formConfig.isView || false,
        };
      }
      if (rowData.field === 'rebateStartDate' || rowData.field === 'rebateEndDate') {
        rowData.props = {
          ...rowData.props,
          // type: 'datetime',
          // valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'rebateStartDate',
            endField: 'rebateEndDate',
          },
        };
      }
      if (rowData.field === 'applyStartDate' || rowData.field === 'applyEndDate') {
        rowData.props = {
          ...rowData.props,
          // type: 'datetime',
          // valueFormat: 'yyyy-MM-dd HH:mm:ss',
          relation: {
            beginField: 'applyStartDate',
            endField: 'applyEndDate',
          },
        };
      }
      return rowData;
    });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
  },
  methods: {
    onExpression(val) {
      this.expressionArea = val;
    },
    // 获取表单数据
    getData() {
      request.get(`/rebate/m/rebate/findById/${this.formConfig.selection[0]}`).then((res) => {
        if (res.success) {
          const { result } = res;
          result.rebateProductList = this.dismountProduct(result.rebateProductList);
          result.assessProductList = this.dismountProduct(result.assessProductList);
          result.policyArea = this.dismountPolicyArea(result);
          result.rebateType = `${result.rebateType}`;
          result.onAccountType = `${result.onAccountType}`;
          this.expressionArea = this.dismountExpression(result.rebateExpressionVos);
          result.organizations = [];
          result.nonOrganizations = [];
          result.customers = [];
          result.nonCustomers = [];
          if (result.onAccountType === '1') {
            this.hiddenFields(true, ['rebateProductList', 'rebateProductListTitle']);
            result.rebateProductList.product = [];
            result.rebateProductList.productLevel = [];
          } else {
            this.hiddenFields(false, ['rebateProductList', 'rebateProductListTitle']);
          }
          this.setValue(result);
        }
      });
    },
    // 拆解商品数据
    dismountProduct(list) {
      const data = {
        product: [],
        productLevel: [],
      };
      list.forEach((v) => {
        if (v.productLevelFlag === 1) {
          data.productLevel.push(v);
        } else {
          data.product.push(v);
        }
      });
      return data;
    },
    // 拆解组织客户
    dismountPolicyArea(data) {
      const policyArea = {
        orgs: [],
        nonOrgs: [],
        customers: [],
        nonCustomers: [],
      };
      data.organizations.forEach((v) => {
        policyArea.orgs.push(v);
      });
      data.nonOrganizations.forEach((v) => {
        policyArea.nonOrgs.push(v);
      });
      data.customers.forEach((v) => {
        policyArea.customers.push({
          customerCode: v.cusCode,
          customerName: v.cusName,
        });
      });
      data.nonCustomers.forEach((v) => {
        policyArea.nonCustomers.push({
          customerCode: v.cusCode,
          customerName: v.cusName,
        });
      });
      return policyArea;
    },
    // 拆解公式
    dismountExpression(data) {
      const rebateExpressionVos = [];
      data.forEach((v) => {
        rebateExpressionVos.push({
          computeExpress: v.computeExpress,
          computeJson: JSON.parse(v.computeJson || '[]'),
          conditionExpress: v.conditionExpress,
          conditionJson: JSON.parse(v.conditionJson || '[]'),
        });
      });
      return rebateExpressionVos;
    },
    // 提交
    async submit() {
      if (this.getFormData()) {
        // 处理公式数据
        const rebateExpressionVos = this.handleExpression();
        // 处理返利商品数据
        const rebateProductList = this.handleRebateProduct();
        // 处理考核商品数据
        const assessProductList = this.handleAssessProductList();
        const policyArea = this.handlePolicyArea();
        // 获取参数
        const params = {
          ...this.getFormData(),
          ...policyArea,
          rebateExpressionVos,
          rebateProductList,
          assessProductList,
        };
        delete params.policyArea;
        delete params.rebateProductListTitle;
        delete params.assessProductListTitle;
        delete params.policyAreaTitle;
        delete params.rebateExpressionVosTitle;
        let path = '';
        if (this.formConfig.selection[0]) {
          path = '/rebate/m/rebate/update';
        } else {
          path = '/rebate/m/rebate/add';
        }
        // console.log(params);
        request.post(path, params).then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
      return true;
    },

    // 处理公式数据
    handleExpression() {
      // 去掉公式里面的空格，把对象转成字符串
      const rebateExpressionVos = [];
      this.expressionArea.forEach((v) => {
        rebateExpressionVos.push({
          conditionExpress: v.conditionExpress.split(' ').join(''),
          computeExpress: v.computeExpress.split(' ').join(''),
          conditionJson: JSON.stringify(v.conditionJson),
          computeJson: JSON.stringify(v.computeJson),
          rebateCode: this.getFieldValue('code'),
        });
      });
      return rebateExpressionVos;
    },
    // 处理商品数据
    handleRebateProduct() {
      const rebateProductList = [];
      this.getFieldValue('rebateProductList').product.forEach((v) => {
        rebateProductList.push({
          ...v,
          assessFlag: 0,
          productLevelFlag: 0,
          id: '',
        });
      });
      this.getFieldValue('rebateProductList').productLevel.forEach((v) => {
        rebateProductList.push({
          ...v,
          assessFlag: 0,
          productLevelFlag: 1,
          id: '',
        });
      });
      return rebateProductList;
    },
    // 处理考核商品数据
    handleAssessProductList() {
      const assessProductList = [];
      this.getFieldValue('assessProductList').product.forEach((v) => {
        assessProductList.push({
          ...v,
          assessFlag: 1,
          productLevelFlag: 0,
          id: '',
        });
      });
      this.getFieldValue('assessProductList').productLevel.forEach((v) => {
        assessProductList.push({
          ...v,
          assessFlag: 1,
          productLevelFlag: 1,
          id: '',
        });
      });
      return assessProductList;
    },
    // 处理政策范围数据
    handlePolicyArea() {
      const policyArea = {
        organizations: [],
        nonOrganizations: [],
        customers: [],
        nonCustomers: [],
      };
      // 处理包含组织数据
      this.getFieldValue('policyArea').orgs.forEach((v) => {
        policyArea.organizations.push({
          ...v,
          customerName: '',
          customerCode: '',
          containFlag: 1,
          orgFlag: 1,
          id: '',
        });
      });
      // 处理非包含组织数据
      this.getFieldValue('policyArea').nonOrgs.forEach((v) => {
        policyArea.nonOrganizations.push({
          ...v,
          customerName: '',
          customerCode: '',
          containFlag: 0,
          orgFlag: 1,
          id: '',
        });
      });
      // 处理包含客户数据
      this.getFieldValue('policyArea').customers.forEach((v) => {
        policyArea.customers.push({
          ...v,
          orgCode: '',
          orgName: '',
          cusCode: v.customerCode,
          cusName: v.customerName,
          containFlag: 1,
          orgFlag: 0,
          id: '',
        });
      });
      // 处理非包含客户数据
      this.getFieldValue('policyArea').nonCustomers.forEach((v) => {
        policyArea.nonCustomers.push({
          ...v,
          orgCode: '',
          orgName: '',
          cusCode: v.customerCode,
          cusName: v.customerName,
          containFlag: 0,
          orgFlag: 0,
          id: '',
        });
      });
      return policyArea;
    },
  },
};
</script>
