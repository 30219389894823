var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("返利基准数据")]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "variable_box" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.rebateVariable, function (item, i) {
              return _c("el-col", { key: i, attrs: { span: 4 } }, [
                _c(
                  "div",
                  {
                    class: { variable_col: true, "is-var": item.banClick },
                    on: {
                      click: function ($event) {
                        return _vm.addVariable(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(item.name || "") + " (" + _vm._s(item.code) + ")"
                    ),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("公式符号")]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "variable_box" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.rebateSymbol, function (item, i) {
              return _c("el-col", { key: i, attrs: { span: 2 } }, [
                _c(
                  "div",
                  {
                    class: { variable_col: true, "is-brackets": item.banClick },
                    on: {
                      click: function ($event) {
                        return _vm.addVariable(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(item.name || "") + " (" + _vm._s(item.code) + ")"
                    ),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("数字")]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "variable_box" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.rebateNumber, function (item, i) {
              return _c("el-col", { key: i, attrs: { span: 2 } }, [
                _c(
                  "div",
                  {
                    class: { variable_col: true, "is-var": item.banClick },
                    on: {
                      click: function ($event) {
                        return _vm.addVariable(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(item.name || "") + " (" + _vm._s(item.code) + ")"
                    ),
                  ]
                ),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("返利公式")]),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "variable_box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.addExpression },
            },
            [_vm._v("添加公式")]
          ),
          _vm._l(_vm.expressionName, function (item, i) {
            return _c(
              "el-row",
              { key: i, attrs: { gutter: 60 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "top" }, [
                      _c("span", [_vm._v("返利条件")]),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-back" },
                              on: {
                                click: function ($event) {
                                  return _vm.backVariable(i, "condition")
                                },
                              },
                            },
                            [_vm._v("后退")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearVariable(i, "condition")
                                },
                              },
                            },
                            [_vm._v("清空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("el-input", {
                      class: {
                        "focus-text":
                          _vm.focusDemo &&
                          _vm.focusDemo.index === i &&
                          _vm.focusDemo.key === "condition",
                        text: true,
                      },
                      attrs: {
                        type: "textarea",
                        readonly: "",
                        placeholder: "请输入内容",
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.setVariable(i, "condition")
                        },
                        blur: _vm.blurVariable,
                      },
                      model: {
                        value: item.conditionExpress,
                        callback: function ($$v) {
                          _vm.$set(item, "conditionExpress", $$v)
                        },
                        expression: "item.conditionExpress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "top" }, [
                      _c("span", [_vm._v("返利公式")]),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-back" },
                              on: {
                                click: function ($event) {
                                  return _vm.backVariable(i, "compute")
                                },
                              },
                            },
                            [_vm._v("后退")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearVariable(i, "compute")
                                },
                              },
                            },
                            [_vm._v("清空")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("el-input", {
                      class: {
                        "focus-text":
                          _vm.focusDemo &&
                          _vm.focusDemo.index === i &&
                          _vm.focusDemo.key === "compute",
                        text: true,
                      },
                      attrs: {
                        type: "textarea",
                        readonly: "",
                        placeholder: "请输入内容",
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.setVariable(i, "compute")
                        },
                        blur: _vm.blurVariable,
                      },
                      model: {
                        value: item.computeExpress,
                        callback: function ($$v) {
                          _vm.$set(item, "computeExpress", $$v)
                        },
                        expression: "item.computeExpress",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }