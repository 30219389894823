<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
  },
  async mounted() {
    this.rule = await this.getFormRule('dms_rebate_detail_calculate');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'computeTime') {
        rowData.props = {
          ...rowData.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        };
      }
      return rowData;
    });
    const date = new Date();
    const y = date.getFullYear();
    const M = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const d = date.getDate();
    const h = date.getHours() + 1 < 10 ? `0${date.getHours()}` : date.getHours();
    const m = date.getMinutes() + 1 < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const s = date.getSeconds() + 1 < 10 ? `0${date.getSeconds()}` : date.getSeconds();

    this.setValue({
      codes: this.formConfig.selection,
      computeTime: `${y}-${M}-${d} ${h}:${m}:${s}`,
    });
  },
  methods: {

    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
      };
      let path = '';
      if (this.formConfig.isTest === '1') {
        path = '/rebate/m/rebateDetail/testCompute';
      } else {
        path = '/rebate/m/rebateDetail/compute';
      }
      request.post(path, params).then((res) => {
        if (res.success) {
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
  },
};
</script>
