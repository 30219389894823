<template>
  <div class="home">
      <TablePage/>

  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'RebateConfig',
  components: {
    TablePage,
  },
  created() {},
  methods: {

  },
};
</script>
