<script>
import TablePage from '../../../../../../components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      // formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  props: {
    propsObj: {
      type: Object,
      default: () => {},
    },
  },
  async created() {
    this.params = {
      rebateCode: this.propsObj.code,
    };
    this.searchFormData.isTest = '0';
    await this.getConfigList('dms_rebate_detail_log');
    this.formData.isTest = '0';
  },
  methods: {

  },
};
</script>
