<template>
  <div class="list-box">
    <el-tabs v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane :label="title + '产品'"
                   name="first"></el-tab-pane>
      <el-tab-pane :label="title + '产品层级'"
                   name="second"></el-tab-pane>
    </el-tabs>
    <el-row>
      <el-col :span="20"
              :offset="1">
        <vxe-toolbar v-if="!isView">
          <template v-slot:buttons>
            <vxe-button @click="addTable('')">添加</vxe-button>
            <vxe-button @click="deleteTable('first')">删除</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                   @checkbox-all="
        ({ records }) => selectAllEvent('firstSelect', records)
      "
                   @checkbox-change="
        ({ records }) => selectChangeEvent('firstSelect', records)
      "
                   :data="firstTableData">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column v-for="(config, index) in firstColumn"
                            :key="index"
                            v-bind="config"></vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

const productColumn = [
  { field: 'productCode', title: '产品编码' },
  { field: 'productName', title: '产品名称' },
];
const levelColumn = [
  { field: 'productLevelCode', title: '产品层级编码' },
  { field: 'productLevelName', title: '产品层级名称' },
];
export default {
  name: 'ProductArea',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    isView: Boolean,
    title: {
      type: String,
      default: '',
    },
  },
  watch: {
    value() {
      if (this.tabType === 'first') {
        this.firstTableData = this.value.product;
      }
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {
        tableType: 'orgUrl',
      }, // 弹框数据
      formName: 'Form',
      activeName: 'first',
      allAlign: null,
      firstColumn: productColumn,
      firstTableData: [],
      secondTableData: [],
      modalType: '', // 弹框包含非包含
      tabType: 'first',
      firstSelect: [],
      secondSelect: [],
    };
  },
  created() {
    // this.firstTableData = this.value.product;
  },
  methods: {
    handleClick(tab) {
      this.tabType = tab.name;
      if (tab.name === 'second') {
        this.firstColumn = levelColumn;
        this.firstTableData = this.value.productLevel;
      } else if (tab.name === 'first') {
        this.firstColumn = productColumn;
        this.firstTableData = this.value.product;
      }
    },
    addTable(val) {
      this.modalType = val || '';
      let params;
      if (this.tabType === 'first') {
        const list = val ? this.secondTableData : this.firstTableData;
        params = {
          functionCode: 'dms_rebate_policy_product',
          data: list,
          idKey: 'productCode',
        };
      } else if (this.tabType === 'second') {
        const list = val ? this.secondTableData : this.firstTableData;
        params = {
          functionCode: 'dms_rebate_policy_product_level',
          data: list,
          idKey: 'productLevelCode',
        };
      }
      this.$refs.selectConfig.openSelectModal(params);

      // this.openModal();
    },
    onGetSelect(val) {
      if (this.tabType === 'second') {
        this.value.productLevel = val;
      } else if (this.tabType === 'first') {
        this.value.product = val;
      }
      this.firstTableData = val;
    },
    deleteTable(val) {
      const list = [];
      const code = this.tabType === 'second' ? 'productLevelCode' : 'productCode';
      this[`${val}TableData`].forEach((v) => {
        let notSelect = true;
        if (this[`${val}Select`].filter((a) => a[code] === v[code]).length > 0) {
          notSelect = false;
        }
        if (notSelect) {
          list.push(v);
        }
      });
      this[`${val}TableData`] = list;
      this[`${val}Select`] = [];
      if (this.tabType === 'second') {
        this.value.productLevel = this.firstTableData;
      } else if (this.tabType === 'first') {
        this.value.product = this.firstTableData;
      }
    },
    selectChangeEvent(val, records) {
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
