<script>
import TablePage from '../../../../../../components/table_page';
import { Form, Calculate, DetailLog } from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
    Calculate,
    DetailLog,
  },
  data() {
    return {
      formConfig: {},
      selection: [],
      configs: {
        tableConfig: {
          idKey: 'code',
        },
      },
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  created() {
    this.getConfigList('dms_rebate_policy_table');
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.formConfig.isView = true;
        this.openFull();
      } else if (val.code === 'rebate_calculation') {
        if (this.selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        this.modalConfig.title = '返利计算';
        this.modalConfig.width = '400px';
        this.modalConfig.height = '200px';
        this.formConfig.selection = this.selection;
        this.formName = 'Calculate';
        this.openModal();
      } else if (val.code === 'test_calculate') {
        if (this.selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        this.modalConfig.title = '返利测试计算';
        this.modalConfig.width = '400px';
        this.modalConfig.height = '200px';
        this.formConfig.selection = this.selection;
        this.formConfig.isTest = '1';
        this.formName = 'Calculate';
        this.openModal();
      } else if (val.code === 'rebate_calculation_log') {
        this.modalConfig.title = '返利计算日志';
        this.propsObjInData = row;
        // this.formConfig.isTest = '1';
        this.formName = 'DetailLog';
        this.openFull();
      }
      return true;
    },
  },
};
</script>
