<template>
  <div>
    <el-button @click="openFull"
               v-if="!isView"
               type="text"
               icon="el-icon-circle-plus-outline">添加公式</el-button>
    <div class="variable_box">
      <el-row :gutter="60"
              v-for="(item,i) in expressionName"
              :key="i">
        <el-form ref="ruleForm"
                 :rules="rules"
                 :model="item">
          <el-col :span="12">
            <el-form-item required
                          label="返利条件"
                          prop="conditionExpress">
              <el-input type="textarea"
                        readonly
                        :class="{text : true}"
                        placeholder="请输入内容"
                        v-model="item.conditionExpress">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item required
                          label="返利公式"
                          prop="computeExpress">
              <el-input type="textarea"
                        readonly
                        :class="{text : true}"
                        placeholder="请输入内容"
                        v-model="item.computeExpress">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig"
           ref="modalForm"
           @onClose="closeModal"
           @onOk="okModal">
      <ExpressionForm ref="ExpressionForm"
                      :list="expressionList"
                      :name="expressionName" />
    </Modal>
  </div>

</template>

<script>
import Modal from '../modal/components/full.vue';
import ExpressionForm from './components/expression_form.vue';

export default {
  name: 'RebateExpression',
  components: {
    Modal,
    ExpressionForm,
  },
  props: {
    value: Array,
    isView: Boolean,
  },
  data() {
    return {
      modalConfig: {
        type: 'Drawer',
        title: '选择',
        showFooter: true,
      }, // 弹框信息
      formConfig: {}, // 弹框数据
      formName: 'Form',
      expressionList: [],
      expressionName: [],
      rules: {
        conditionExpress: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        computeExpress: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    value(n) {
      const expressionList = [];
      const expressionName = [];
      this.value.forEach((v) => {
        expressionList.push({
          computeExpress: this.handleVariable(
            JSON.parse(v.computeJson || '[]'),
          ),
          computeJson: JSON.parse(v.computeJson || '[]'),
          conditionExpress: this.handleVariable(
            JSON.parse(v.conditionJson || '[]'),
          ),
          conditionJson: JSON.parse(v.conditionJson || '[]'),
        });
        expressionName.push({
          computeExpress: this.handleVariableName(
            JSON.parse(v.computeJson || '[]'),
          ),
          conditionExpress: this.handleVariableName(
            JSON.parse(v.conditionJson || '[]'),
          ),
        });
      });
      this.expressionList = expressionList;
      this.expressionName = expressionName;
    },
  },
  created() {},
  methods: {
    onGetSelect() {},
    // 监听变量
    handleVariable(data) {
      let name = '';
      data.forEach((v) => {
        // const rowData = v;
        // 编译显示的字符串
        if (v.key === 'number') {
          name += `${v.code}`;
        } else {
          name += ` ${v.code} `;
        }
        // return rowData;
      });
      return name;
    },
    // 监听名称变量
    handleVariableName(data) {
      let name = '';
      data.forEach((v) => {
        // const rowData = v;
        // 编译显示的字符串
        if (!v.key) {
          name += ` ${v.name} `;
        } else if (v.key === 'symbol') {
          name += ` ${v.code} `;
        } else if (v.key === 'number') {
          name += `${v.code}`;
        }
        // return rowData;
      });
      return name;
    },
    // 打开抽屉
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    okModal() {
      this.expressionList = [];
      this.expressionName = [];
      this.$refs.ExpressionForm.$data.expressionList.forEach((v) => {
        const rowData = v;
        if (rowData.conditionExpress || rowData.computeExpress) {
          this.expressionList.push(rowData);
        }
      });
      this.$refs.ExpressionForm.$data.expressionName.forEach((v) => {
        const rowData = v;
        if (rowData.conditionExpress || rowData.computeExpress) {
          this.expressionName.push(rowData);
        }
      });
      this.$emit('onExpression', this.expressionList);
      this.modalConfig = { ...this.modalConfig, visible: false };
      this.$nextTick(() => {
        this.$refs.ruleForm.forEach((v) => {
          v.validate((valid) => {
            console.log(valid);
          });
        });
      });
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
.variable_box {
  padding: 0 20px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text {
    margin-bottom: 20px;
  }
}
</style>
